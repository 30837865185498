import React, { useState } from "react";
import logo from "../logo.png"
import Global from "../utils/Globals";
import { useNavigate } from "react-router-dom";
export default function Navbar() {
  const [isMenuOpen,setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <nav style={{fontFamily:Global.appTheme.primaryFont}} className="bg-transparent font-Poppins p-4 relative">
      <div className="flex items-center justify-between">
        {/* LOGO */}
        <div className="md:hidden order-1">
          <button
            onClick={() => setIsMenuOpen(true)}
            className="w-5 cursor-pointer sm:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-list" // Updated class to className
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd" // Updated fill-rule to fillRule
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </button>
        </div>

        {/* Logo will be right in mobile view */}
        <div onClick={()=>navigate('/')} className="sm:items-center order-3 md:order-1 md:mr-auto cursor-pointer">
          <img src={logo} style={{width:"6rem"}} />
        </div>

        {/* Desktop Menu View */}
        <ul className="hidden md:flex space-x-4 order-2 md:order-2 font-Poppins font-semibold md:ml-auto">
          <li>
            <a style={{fontFamily:Global.appTheme.primaryFont}}  href="#" className="text-black">
              Home
            </a>
          </li>
          <li>
            <a style={{fontFamily:Global.appTheme.primaryFont}}  href="#" className="text-black">
              Services
            </a>
          </li>
          <li>
            <a href="#" className="text-black">
              Why Us
            </a>
          </li>
          <li>
            <a href="#" className="text-black">
              Contact
            </a>
          </li>
        </ul>
      </div>

      {/* Mobile Menu opacity background to be blurred */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}

      {/* Mobile View */}
      <div
        className={`fixed top-0 right-0 bottom-0 z-20 bg-white transition-all duration-300 ease-in-out ${
          isMenuOpen ? "w-10/12 h-screen" : "w-0"
        } overflow-hidden`}
      >
        <div className="flex flex-col text-gray-600">
          <div
            onClick={() => setIsMenuOpen(false)}
            className="flex items-center gap-4 p-3 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg" // Updated class to className
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>

          <ul className="flex flex-col gap-3">
            <li>
              <a
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                href="#"
                className="text-black px-8"
              >
                Home
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                href="#"
                className="text-black px-8"
              >
                Services
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                href="#"
                className="text-black px-8"
              >
                Why Us
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                href="#"
                className="text-black px-8"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
