import React from "react";
import Global from "../utils/Globals";

export default function ServiceArea() {
  return (
    <div style={{fontFamily:Global.appTheme.primaryFont}} className="relative flex flex-col items-center p-8">
      {/* Heading */}

      {/* Desktop Designs */}
      <div className="hidden lg:flex flex-col w-full gap-4">
        {/* Design 1 - 3 Rows */}
        <div className="mb-4 text-center">
          <h1 className="text-2xl font">We are available in your area</h1>
          <p>Currently, we are serving in Nirmal, Adilabad, Nizamabad</p>
        </div>

        {/* Design 2 - 1 Row, 2 Sections */}
        <div className="flex justify-between border">
          <div className="w-2/5 p-1 flex flex-col text-white bg-blue-200">
            <div className=" p-3 bg-slate-900 flex flex-col gap-10 ">
              <h1 className="text-3xl">NIRMAL ADILABAD</h1>
              <p className="text-lg">
              SY.NO.610, NEAR HP PETROL PUMP, ROAD NO 3, SHASTRI NAGAR, NIRMAL, TELANGANA-504106.
              </p>
              <h2 className="text-sm font-bold flex items-center font-mono ">
                VIEW LOCATION
                <span className="ml-1 inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          </div>
          {/* hr line*/}

          {/* maps */}

          <div className="flex justify-center items-center p-4 w-2/3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7540.934406882611!2d78.34526428628764!3d19.087149252527528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcd8c648030eb89%3A0xf04c9529eebbded8!2sHP%20Petrol%20Pump!5e0!3m2!1sen!2sin!4v1730789125972!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-lg shadow-md"
            ></iframe>
           
          </div>
        </div>
      </div>
      {/* Mobile Layout */}
      <div className="flex flex-col lg:hidden gap-4 text-center">
        <h2 className="text-xl">We are available in your area</h2>
        <p>Currently, we are serving in Nirmal, Adilabad, Nizamabad</p>
        <div className="flex justify-center items-center p-4 w-screen">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1931201.7056018913!2d76.93524086395814!3d19.02508227137679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd25d0be6d4dcc7%3A0x851c659ed80ab906!2sNirmal!5e0!3m2!1sen!2sin!4v1730615984614!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-lg shadow-md"
            ></iframe>
          </div>
      </div>
    </div>
  );
}
