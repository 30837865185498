import React from "react";
import Global from "../utils/Globals";

const features = [
  {
    title: "Fast Delivery",
    description: "Get your groceries delivered within 15 minutes, guaranteed!",
    image: "./assets/fast delivery.jpg" 
  },
  {
    title: "Fresh Quality",
    description: "We ensure top quality and freshness for every item.",
    image: "/assets/fresh quality.jpg" 
  },
  {
    title: "Competitive Pricing",
    description: "Affordable prices on all your favorite essentials.",
    image: "/assets/pricing.jpg"
  },
  {
    title: "Easy Ordering",
    description: "Simple and convenient ordering with just a few clicks.",
    image: "/assets/easy order.jpg" 
  },
  {
    title: "Eco-Friendly Packaging",
    description: "We use sustainable packaging to reduce environmental impact.",
    image: "/assets/echofriendly1.jpg" 
  },
];

const WhyUs = () => {
  return (
    <div style={{fontFamily:Global.appTheme.primaryFont}} className="flex flex-col p-8 bg-gray-100 items-center">
      <h2 className="text-2xl font-bold mb-6">Why Choose Us?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:w-1/2 gap-6">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex items-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <img
              src={feature.image}
              alt={feature.title}
              className="w-12 h-12 mr-2"
            />
            <div className="text-left">
              <h4 className="text-xl font-semibold text-green-600 mb-2">
                {feature.title}
              </h4>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;
