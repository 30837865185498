import React from 'react';
import { TiSocialFacebook,TiSocialTwitter,TfiYoutube } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa6";
import { RiPinterestFill } from "react-icons/ri";
import logo from "../logo.png"
import { useNavigate } from 'react-router-dom';
export default function Footer() {
  const services = [
    {
      text:"Grocery",
      clickAction: false,
      navigateTo: '/'
    },
    {
      text:"Fruits",
      clickAction: false,
      navigateTo: '/'
    },
    {
      text:"Vegetables",
      clickAction: false,
      navigateTo: '/'
    },
  ]
  const links = [
    {
      text:"Home",
      clickAction: true,
      navigateTo: '/'
    },
    {
      text:"Terms & Conditions",
      clickAction: true,
      navigateTo: '/terms'
    },
    {
      text:"Privacy Policy",
      clickAction: true,
      navigateTo: '/privacy'
    },
  ]
  return (
    <div className=' bg-[#f6f6f6] font-sans'>

      <div className='flex flex-row col-span-12 space-x-4 justify-between px-20 py-20'>

        <div className='flex flex-col  w-3/5'>
        <img src={logo} className='w-32 -mb-4' />
        <div className='ml-3'>
          <h2 className='text-lg'>Contact Us</h2>
          <p>Email: prudvmartspvtltd@gmail.com</p>
          <p>Phone: +91 9706767970</p>
        </div>
        <div className='flex flex-row space-x-4 mt-4'>
        <TiSocialFacebook className='hover:text-[#ee6c4d]' size={20}/>
        <TiSocialTwitter  className='hover:text-[#ee6c4d]' size={20}/>
        <FaInstagram  className='hover:text-[#ee6c4d]' size={20}/>
        <RiPinterestFill  className='hover:text-[#ee6c4d]' size={20}/>
        <TiSocialFacebook  className='hover:text-[#ee6c4d]' size={20}/>
        </div>
        </div>

        <div className='w-2/5'>
        <ColumnUi title={"Services"} data={services}/>
        </div>

        <div className='w-2/5'>
        <ColumnUi title={"Links"} data={links} />
        </div>

        <div className='w-3/5 flex flex-col space-y-4 text-base'>
    <div className=' font-semibold text-base'>SUBSCRIBE LATEST DEAL</div>
     <div className='flex flex-col space-y-4 text-[#6f6f6f] text-base w-11/12'>
     Subscribe to our newsletter to receive fashion store news on new update.
     </div>
     <div>
     
     </div>
    </div>
        
      </div>


      <div className='h-[1px] bg-[#6f6f6f] w-full bg-opacity-35'></div>

      <div className='text-base text-[#6f6f6f] py-5 px-20'>
      Copyright © 2024 by BigDp
      </div>
    </div>
  );
}

const ColumnUi =({title, data})=> {
  const navigation = useNavigate();
  
  return(
    <div className='flex flex-col space-y-4 text-base text-[#222222]'>
    <div className=' font-semibold uppercase text-base'>{title}</div>
     <div className='flex flex-col space-y-4 text-base'>
     {data.map(it=>{
      return(
        <p className='hover:text-[#ee6c4d] cursor-pointer' onClick={()=>{
          if(it.clickAction) navigation(it.navigateTo)
        }}>{it.text}</p>
      )
     })}
     
     </div>
    </div>
  )
}



// <div>
//       {/* Main Footer Section -- */}
//       <div className="bg-gradient-to-r from-emerald-500 to-purple-500 text-white py-8">
//         <div className="container mx-auto px-4">
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
//             {/* <!-- Left Column --> */}
//             <div className="m-12">
//               <h3 className="text-xl font-semibold">bigdpapp</h3>
//               <ul className="mt-4 space-y-2 text-lg">
//                 <li><a href="#" className="hover:underline">Terms & Conditions</a></li>
//                 <li><a href="#" className="hover:underline">Help/Support</a></li>
//                 <li><a href="#" className="hover:underline">Customer Consultant</a></li>
//               </ul>
//             </div>
//             {/* <!-- Center Column --> */}
//             <div className="flex">
//               <div className="w-1/3"></div>
//               <div className="w-1/3 "></div>
//             </div>
//             <div className="just-content text-right text-xl m-12">
//               <h3 className="text-lg font-semibold ">Community</h3>
//               <ul className="mt-4 space-y-2 text-lg">
//                 <li><a href="#" className="hover:underline">Community</a></li>
//                 <li><a href="#" className="hover:underline">Blog</a></li>
//                 <li><a href="#" className="hover:underline">Forum</a></li>
//               </ul>
//             </div>
//           </div>
  
//           {/* <!-- Bottom Footer --> */}
//           <div className="border-t border-white mt-8 pt-4 text-sm text-center">
//             <p>&copy; Copyright 2024 bigapp | CompanyName & Logo Trade Marks Their Concern Company</p>
//             <div className="flex justify-center space-x-4 mt-2">
//               <a href="#" className="hover:underline">Twitter</a>
//               <a href="#" className="hover:underline">Facebook</a>
//               <a href="#" className="hover:underline">Google+</a>
//               <a href="#" className="hover:underline">LinkedIn</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>