

export default class Global{

    
    static appTheme = {
        primaryColor: "#4b9634",
        secondaryColor: "#FAF5F1",
        headingFont: "Poppins-Bold",
        primaryFont: "Poppins",
        secondaryFont: ""
    }
}