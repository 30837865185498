import React from "react";
import Global from "../utils/Globals";

export default function HeroSection() {
  return (
    <div style={{fontFamily:Global.appTheme.primaryFont}} className="relative flex flex-col font-mono lg:flex-row mt-10 lg:mt-20 px-5 lg:px-10">
      {/* Background for mobile with opacity */}
      <div 
        className="absolute inset-0 bg-cover bg-center opacity-90 hidden md:hidden lg:hidden" 
        style={{ backgroundImage: `url('assets/bg-veg.jpg')` }}
      ></div>

      {/* Content section - Left on laptop/desktop */}
      <div  className="relative w-full lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left order-2 lg:order-1 p-6 lg:p-0">
        <h1 style={{fontFamily:Global.appTheme.primaryFont}} className="text-3xl md:text-4xl lg:text-5xl text-black font-semibold leading-snug mb-4 text">
          Welcome, 
          <br /> We deliver products home in 
          <span className="font-light"> 15 mins</span>
        </h1>
        <p className="text-sm md:text-base mb-6 lg:text-black sm:text-yellow-400">
          At bigdpmart, we bring a world of products right to your fingertips.
          From daily essentials to exclusive brands, we aim to make shopping
          easy, fast, and convenient. Enjoy a seamless shopping experience
          with a wide variety of categories, unbeatable prices, and fast
          delivery.
        </p>
        <div className="flex justify-center lg:justify-start space-x-4">
          {/* Play Store Image */}
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
            <img src="assets/playstore.jpg" alt="Download on Play Store" className="w-32 rounded-md h-auto" />
          </a>
          {/* iOS Store Image */}
          <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <img src="assets/apple.png" alt="Download on App Store" className="w-32 rounded-md h-auto" />
          </a>
        </div>
      </div>

      {/* Hero Image - Right on laptop/desktop, visible on larger screens */}
      <div className="relative w-full lg:w-1/2 flex justify-center lg:justify-end order-1 lg:order-2 mt-8 lg:mt-0">
        <img
          src="assets/herosectionbg.jpg"
          alt="Hero section background"
          className="hidden lg:block w-full h-auto lg:max-w-md lazy-load" // Hide on mobile, show on desktop
         />
      </div>
    </div>
  );
}
