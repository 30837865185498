import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Terms(){
    return(
        <div className='h-full w-full'>
            <Navbar />
            <div className="bg-gray-100 py-8 px-4 font-mono">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Terms and Conditions
        </h1>
        <p className="text-sm text-gray-500 mb-4">
          Last Updated: [05/11/2024]
        </p>
        <p className="mb-4">
          Welcome to BigDpMarts ("we," "us," or "our")! By accessing or using
          our website and services, you ("Customer," "User," or "you") agree to
          be bound by the following Terms and Conditions. Please read them
          carefully. If you do not agree to these terms, you may not use our
          services.
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">1. Service Overview</h2>
        <p className="mb-4">
          BigDpMarts provides an online platform that allows users to browse,
          purchase, and receive grocery and other products through a fast
          delivery service. Delivery times are typically within 15 minutes but
          may vary based on availability, location, and demand.
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">2. Account Registration</h2>
        <p className="mb-4">
          To use our service, you may need to create an account. You agree to
          provide accurate, current, and complete information when registering
          and to keep this information up to date.
        </p>

        <h3 className="text-md font-semibold mt-4 mb-2">2.1 User Responsibilities</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Age Requirement: You must be at least 18 years old or have parental consent to use our service.</li>
          <li>Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
          <li>Accuracy of Information: You agree to provide truthful and accurate details, including your address and payment information, to facilitate timely delivery.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-6 mb-2">3. Orders and Payments</h2>
        <p className="mb-4">
          All orders are subject to product availability, and pricing may change
          without prior notice.
        </p>

        <h3 className="text-md font-semibold mt-4 mb-2">3.1 Payment</h3>
        <p className="mb-4">
          Payments are due at the time of order placement. We accept various
          payment methods as displayed on the site. By submitting payment
          information, you represent that you are authorized to use the chosen
          payment method.
        </p>

        <h3 className="text-md font-semibold mt-4 mb-2">3.2 Order Confirmation</h3>
        <p className="mb-4">
          You will receive an order confirmation via email or SMS upon
          successful payment. This confirmation does not guarantee delivery
          within a specific timeframe due to potential unforeseen circumstances.
        </p>

        <h3 className="text-md font-semibold mt-4 mb-2">3.3 Delivery Terms</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Delivery Area: We currently serve select locations. Ensure your address falls within our delivery area.</li>
          <li>Delivery Accuracy: It is your responsibility to provide accurate delivery instructions to avoid failed delivery attempts.</li>
          <li>Failed Deliveries: In case of a failed delivery attempt due to incorrect information or the recipient being unavailable, additional delivery fees may apply.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-6 mb-2">4. Cancellations and Refunds</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>Cancellation Before Dispatch: Orders canceled before dispatch are eligible for a full refund.</li>
          <li>Refunds for Damaged/Incorrect Items: Contact customer service within 24 hours if items are damaged or incorrect. We will offer a replacement or refund based on our discretion.</li>
          <li>Non-Refundable: Orders already delivered or in transit may not be eligible for refunds.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-6 mb-2">5. Use of the Website and Mobile App</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>Prohibited Activities: You agree not to engage in any activity that disrupts or attempts to disrupt the website, app, or associated services.</li>
          <li>Intellectual Property: All content, logos, designs, and trademarks displayed on our site are the property of BigDpMarts. You may not reproduce, distribute, or modify any content without our permission.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-6 mb-2">6. Privacy</h2>
        <p className="mb-4">
          Your use of our services is also governed by our{" "}
          <a href="/privacy-policy" className="text-blue-500 hover:underline">
            Privacy Policy
          </a>
          , which outlines how we collect, use, and protect your personal
          information.
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">7. Limitation of Liability</h2>
        <p className="mb-4">
          BigDpMarts will not be liable for any indirect, incidental, or
          consequential damages arising from the use of our services, including
          but not limited to delivery delays, product availability, or data
          loss.
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">8. Indemnification</h2>
        <p className="mb-4">
          You agree to indemnify and hold BigDpMarts harmless from any claims,
          losses, liabilities, damages, and expenses arising from your use of
          the service, violation of these Terms, or infringement of any
          third-party rights.
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">9. Modifications to the Terms</h2>
        <p className="mb-4">
          We reserve the right to update or modify these Terms at any time. Any
          changes will be posted on this page with the updated date. Continued
          use of our services after changes are posted constitutes your
          acceptance of the modified terms.
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">10. Governing Law</h2>
        <p className="mb-4">
          These Terms and any disputes arising from your use of the service will
          be governed by the laws of [Jurisdiction/Location].
        </p>

        <h2 className="text-lg font-semibold mt-6 mb-2">11. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms and Conditions, please
          contact us at:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Email: <a href="mailto:prudvmartspvtltd@gmail.com" className="text-blue-500 hover:underline">prudvmartspvtltd@gmail.com</a></li>
          <li>Phone: +91 9706767970</li>
        </ul>
      </div>
            </div>
            <Footer />
        
        
        </div>
        
    )
}