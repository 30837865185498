import { createHashRouter } from "react-router-dom";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
const router = createHashRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/terms",
        element: <Terms />,
    },
    {
        path: "/privacy",
        element: <Privacy />,
    },
]);

export default router;