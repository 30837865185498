import React from "react";
import Global from "../utils/Globals";

export default function Categories() {
  return (
    <div style={{fontFamily:Global.appTheme.primaryFont}} className="flex flex-col items-center justify-evenly p-5 w-full h-full">
      <div className="container mx-auto rounded-sm py-8">
        <h2 className="text-2xl font-semibold text-center mb-8">FOOD CATEGORIES</h2>
        {/* <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mx-auto w-full"> */}
       

        <div className="flex flex-col lg:flex-row items-center justify-around space-y-8 lg:space-y-0 lg:space-x-4 w-full">
         
        
         
          <div className=" bg-transparent shadow-lg rounded-lg p-4 relative">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8Lax5WT8ID-h-TgOpYx7touvpCs-SD5r2xQ&s"
              alt="Groceries"
              className="mx-auto mb-4  rounded-sm"
            />
            <h3 className="text-center font-medium">Groceries</h3>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-4 relative ">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGd6QjvL8GMqDzdDGmJ8f2YhtqAJAl1HQlCw&s"
              alt="Fresh Vegetables"
              className="mx-auto mb-4"
            />
            <h3 className="text-center font-medium">Fresh Vegetables</h3>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-4 relative ">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2J9F62hrGMqLWnUJ4eERfekSD8OzOhirGzw&s"
              alt="Fresh Fruits"
              className="mx-auto mb-4"
            />
            <h3 className="text-center font-medium">Fresh Fruits</h3>
          </div>
        </div>
        
      </div>
    </div>
  );
}
