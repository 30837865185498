import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import HowItWorks from "../components/HowItWorks";
import ServiceArea from "../components/ServiceArea";
import Categories from "../components/Categories";
import WhyUs from "../components/WhyUs";
import AppsDownload from "../components/AppsDownload";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <HowItWorks />
      <ServiceArea />
      <Categories />
      <WhyUs />
      <AppsDownload />
      <Footer />
    </div>
  );
}
