import React from 'react';

export default function HowItWorks() {
  return (
    <div className="flex flex-col items-center justify-between p-8 bg-gray-100 w-full h-full font-mono">
      <h2 className="text-3xl font-bold mb-6 text-blue-700">How It Works</h2>
      
      <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:space-x-8 w-full">
        
        {/* Order Section */}
        <div className="flex flex-row items-center p-6 bg-white shadow-lg rounded-lg lg:w-1/3">
          <img src="./assets/order.jpg" alt="Order icon showing a shopping cart" className="w-24 h-24 mr-4" />
          <div className="flex-1">
            <h3 className="text-xl font-semibold">1. Order</h3>
            <p className="text-gray-700">
              Browse our app and add items to your cart.
            </p>
          </div>
        </div>

        {/* Prepare Section */}
        <div className="flex flex-row items-center p-6 bg-white shadow-lg rounded-lg lg:w-1/3">
          <img src="./assets/prepare.jpg" alt="Packing icon with items being packed" className="w-24 h-24 mr-4" />
          <div className="flex-1">
            <h3 className="text-xl font-semibold">2. Packing</h3>
            <p className="text-gray-700">
              We pack your order immediately upon request.
            </p>
          </div>
        </div>

        {/* Delivery Section */}
        <div className="flex flex-row items-center p-6 bg-white shadow-lg rounded-lg lg:w-1/3">
          <img src="./assets/delivery.jpg" alt="Delivery icon showing a delivery truck" className="w-24 h-24 mr-4" />
          <div className="flex-1">
            <h3 className="text-xl font-semibold">3. Delivery</h3>
            <p className="text-gray-700">
              Your groceries are on the way — delivered within 15 minutes.
            </p>
          </div>
        </div>
      
      </div>
    </div>
  )
}