import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function Privacy() {
  return (
    <div className='h-full w-full'>
    <Navbar />
    <div className="bg-gray-100 font-mono leading-relaxed text-gray-700">

<div className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg">
  <h1 className="text-3xl font-bold text-center mb-4">Privacy Policy</h1>
  <p className="text-center text-gray-600 mb-8">Last Updated:05/11/2024</p>
  
  <section className="mb-8">
    <p className="text-lg">
      BigDpMarts ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you use our website or mobile application to order groceries and other goods with fast delivery. By accessing or using our services, you consent to the terms outlined in this Privacy Policy.
    </p>
  </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
    
    <div className="mb-6">
      <h3 className="text-xl font-medium mb-2">1.1 Personal Information</h3>
      <ul className="list-disc list-inside space-y-2">
        <li><strong>Contact Details:</strong> Includes your full name, email address, and phone number.</li>
        <li><strong>Account Information:</strong> Username, password, and profile information.</li>
        <li><strong>Delivery Information:</strong> Delivery address, delivery instructions, and location data (with your consent) for precise drop-off.</li>
      </ul>
    </div>

    <div className="mb-6">
      <h3 className="text-xl font-medium mb-2">1.2 Transaction Data</h3>
      <ul className="list-disc list-inside space-y-2">
        <li><strong>Order Information:</strong> Details of products ordered, frequency, and transaction amounts.</li>
        <li><strong>Payment Details:</strong> Credit card information or other payment details securely processed via a third-party provider. We do not store full payment details on our servers.</li>
      </ul>
    </div>

    <div className="mb-6">
      <h3 className="text-xl font-medium mb-2">1.3 Usage Data</h3>
      <ul className="list-disc list-inside space-y-2">
        <li><strong>Browsing Activity:</strong> Information about the pages you visit on our site, links clicked, and navigation within the site or app.</li>
        <li><strong>Device and Technical Data:</strong> Device type, operating system, and IP address to optimize performance.</li>
      </ul>
    </div>

    <div className="mb-6">
      <h3 className="text-xl font-medium mb-2">1.4 Location Data</h3>
      <p>To facilitate accurate and timely delivery, we may collect precise or approximate location information (with your consent) when you use our mobile app or website.</p>
    </div>
  </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
    <ul className="list-disc list-inside space-y-2">
      <li><strong>Order Processing:</strong> To process your orders and coordinate delivery, including communicating with drivers and ensuring accuracy.</li>
      <li><strong>Delivery Coordination:</strong> To optimize delivery times by using your address and location data.</li>
      <li><strong>Customer Support:</strong> To provide customer service, respond to inquiries, and handle complaints.</li>
      <li><strong>Service Optimization:</strong> To understand and improve your experience, including troubleshooting issues and testing features.</li>
      <li><strong>Marketing:</strong> With your consent, to inform you of promotions, special offers, and updates related to BigDpMarts.</li>
    </ul>
  </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">3. Sharing Your Information</h2>
    <ul className="list-disc list-inside space-y-2">
      <li><strong>Delivery Drivers:</strong> Limited information, such as your name, contact number, and delivery address, is shared with delivery drivers to complete orders.</li>
      <li><strong>Payment Processors:</strong> We work with third-party payment processors who handle your payment securely.</li>
      <li><strong>Service Providers:</strong> Third parties that help with analytics, customer service, and website maintenance.</li>
      <li><strong>Legal Compliance:</strong> We may share information to comply with legal obligations, resolve disputes, and enforce our terms.</li>
    </ul>
  </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
    <p>We take data security seriously and use administrative, technical, and physical safeguards to protect your personal information. However, no online system can be completely secure.</p>
  </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
    <p>We retain your information only as long as necessary to fulfill the purposes outlined in this policy, or as required by law.</p>
  </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">6. Your Rights and Choices</h2>
    <ul className="list-disc list-inside space-y-2">
      <li><strong>Access and Correction:</strong> Request access to or correction of your information.</li>
      <li><strong>Opt-Out of Marketing:</strong> You can opt out of receiving marketing communications at any time.</li>
      <li><strong>Data Deletion:</strong> Request deletion of your information, subject to legal requirements.</li>
    </ul>
  </section>
  <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">7. Use of Cookies and Tracking Technologies</h2>
      <p>We use cookies to enhance your experience and gather insights on website usage. You can manage your cookie preferences through your browser settings.</p>
    </section>
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">8. Children’s Privacy</h2>
      <p>Our services are intended for users 13 and older. We do not knowingly collect information from children under 13.</p>
    </section>
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">9. Updates to This Privacy Policy
      </h2>
      <p>We may update this Privacy Policy periodically. We will notify you of changes by posting the updated policy on this page.</p>
    </section>

  <section className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
    <p>If you have any questions, please contact us at:</p>
    <ul className="list-disc list-inside space-y-2">
      <li><strong>Email:</strong>prudvmartspvtltd@gmail.com</li>
      <li><strong>Phone:</strong>+919706767970</li>
    </ul>
  </section>
</div>

    </div>
    <Footer />
    </div>
  )
}